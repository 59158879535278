import { 
    THEME_COLOR, 
    DARK_MODE,
    GRAY_SCALE,
    BORDER,
    BODY_BACKGROUND
  } from 'constants/ThemeConstant'
  
  export function rgba(hex, opacity = 1) {
    if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      throw new Error('Invalid Hex');
    }
  
    const decimal = parseInt(hex.substring(1), 16);
  
    const r = (decimal >> 16) & 255;
    const g = (decimal >> 8) & 255;
    const b = decimal & 255;
  
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  
  export const baseTheme = {
    borderRadius: 8, // 기본적으로 borderRadius를 줄임
    colorPrimary: THEME_COLOR.BLUE,
    colorSuccess: THEME_COLOR.CYAN,
    colorWarning: THEME_COLOR.GOLD,
    colorError: THEME_COLOR.VOLCANO,
    colorInfo: THEME_COLOR.BLUE,
    colorText: GRAY_SCALE.GRAY,
    colorBorder: BORDER.BASE_COLOR,
    colorBgBody: BODY_BACKGROUND,
    fontSize: 12,
    controlHeight: 32, // 기본 controlHeight를 줄임
    controlHeightLG: 40, // Large controlHeight를 줄임
    controlHeightSM: 28, // Small controlHeight를 줄임
    fontFamily: `'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji'`,
    fontSizeHeading2: 18, // 헤딩 폰트 크기를 줄임
    fontSizeHeading4: 14,  // 헤딩 폰트 크기를 줄임
  }
  
  const getBaseComponentThemeConfig = (
    {
        Button = {},
        Menu = {}, 
        Card = {},
        Table = {},
        Select = {},
        DatePicker = {},
        Layout = {},
        Input = {},
        Dropdown = {},
        Calendar = {}
    } = {}
  ) => {
    return {
        Button: {
            ...Button,
            controlHeight: 32, // Button 높이를 줄임
        },
        Dropdown: {
            controlPaddingHorizontal: 16,
            controlHeight: 32, // Dropdown 높이를 줄임
            borderRadiusLG: 8, // borderRadius를 줄임
            paddingXXS: '2px 0px', // 패딩을 줄임
            ...Dropdown
        },
        Calendar: {
            ...Calendar,
            controlHeight: 32, // Calendar 높이를 줄임
        },
        Checkbox: {
            lineWidth: 2,
            borderRadiusSM: 4
        },
        Card: {
            colorTextHeading: GRAY_SCALE.GRAY_DARK,
            paddingLG: 16, // Card 패딩을 줄임
            ...Card
        },
        Layout: {
            colorBgBody: BODY_BACKGROUND,
            colorBgHeader: GRAY_SCALE.WHITE,
            ...Layout
        },
        Breadcrumb: {
            colorTextDescription: GRAY_SCALE.GRAY_LIGHT,
            colorText: baseTheme.colorPrimary,
            colorBgTextHover: 'transparent'
        },
        Menu: {
            colorItemBg: 'transparent',
            colorActiveBarHeight: 0,
            colorActiveBarWidth: 3,
            colorItemTextSelectedHorizontal: baseTheme.colorPrimary,
            colorItemTextHover: baseTheme.colorPrimary,
            colorItemTextSelected: baseTheme.colorPrimary,
            colorItemBgSelected: rgba(baseTheme.colorPrimary, 0.1),
            colorItemBgHover: 'transparent',
            radiusItem: 0,
            colorSubItemBg: 'transparent',
            itemMarginInline: 0,
            controlHeightLG: 32, // Large Menu 항목 높이를 줄임
            controlHeightSM: 22, // Small Menu 항목 높이를 줄임
            ...Menu
        },
       
        Input: {
            ...Input,
            controlHeight: 32, // Input 높이를 줄임
        },
        Pagination: {
            paginationItemSize: 24, // Pagination 아이템 크기를 줄임
            borderRadius: '50%',
            colorBgContainer: baseTheme.colorPrimary,
            colorPrimary:  GRAY_SCALE.WHITE,
            colorPrimaryHover: GRAY_SCALE.WHITE,
        },
        Steps: {
            wireframe: true,
            controlHeight: 28, // Steps 높이를 줄임
            waitIconColor: GRAY_SCALE.GRAY_LIGHT
        },
        DatePicker: {
            controlHeight: 32, // DatePicker 높이를 줄임
            controlHeightSM: 24, // Small DatePicker 높이를 줄임
            borderRadiusSM: 4, // Small borderRadius를 줄임
            lineWidthBold: 0,
            ...DatePicker
        },
        Radio: {
            fontSizeLG: 16 // Radio 폰트 크기를 줄임
        },
        Slider: {
            colorPrimaryBorder: rgba(baseTheme.colorPrimary, 0.8),
            colorPrimaryBorderHover: baseTheme.colorPrimary
        },
        Select: {
            paddingXXS: '2px 0px', // Select 패딩을 줄임
            controlHeight: 32, // Select 높이를 줄임
            controlHeightSM: 28, // Small Select 높이를 줄임
            controlItemBgActive: rgba(baseTheme.colorPrimary, 0.1),
            ...Select
        },
        
        TreeSelect: {
            controlHeightSM: 24 // Small TreeSelect 높이를 줄임
        },
        Tooltip: {
            colorBgDefault: rgba(GRAY_SCALE.DARK, 0.75),
            controlHeight: 28 // Tooltip 높이를 줄임
        },
        Timeline: {
            lineType: 'dashed'
        },
        Tag: {
            lineHeight: 1.8 // Tag lineHeight를 줄임
        },
        Table: {
            colorText: GRAY_SCALE.GRAY,
            tableHeaderBg: 'transparent',
            paddingSM: 0, // Adjust Table padding to be smaller
            fontSize: 12, // Adjust font size for smaller text
            paddingXS: 4,
            ...Table
        },
       
    }
  }
  
  export const lightTheme = {
    token: {
        ...baseTheme,
       
        colorTextBase: GRAY_SCALE.GRAY,
    },
    
    components: getBaseComponentThemeConfig()
  }
  
  
  
  export const darkTheme = {
    token: {
        ...baseTheme,
        Alert: {
            colorInfoBg: rgba(DARK_MODE.BG_COLOR, 0.2),
            colorSuccessBg: rgba(DARK_MODE.BG_COLOR, 0.2),
            colorWarningBg: rgba(DARK_MODE.BG_COLOR, 0.2),
            colorErrorBg: rgba(DARK_MODE.BG_COLOR, 0.2),
            // 필요에 따라 다른 속성들도 오버라이드할 수 있습니다.
        },
        colorTextBase: DARK_MODE.TEXT_COLOR,
        colorBgBase: DARK_MODE.BG_COLOR,
        colorBorder: DARK_MODE.BORDER_BASE_COLOR,
        colorText: DARK_MODE.TEXT_COLOR,
    },
    components: getBaseComponentThemeConfig({
        
       
        Button: {
            controlOutline: 'transparent',
            colorBorder: DARK_MODE.BORDER_BASE_COLOR,
            colorText: DARK_MODE.TEXT_COLOR,
            controlHeight: 32, // Button 높이를 줄임
        },
        Card: {
            colorTextHeading: DARK_MODE.HEADING_COLOR,
            paddingLG: 16, // Card 패딩을 줄임
        },
        Layout: {
            colorBgBody: '#1b2531',
            colorBgHeader: DARK_MODE.BG_COLOR
        },
        Menu: {
            colorItemBgSelected: 'transparent',
            colorItemTextSelectedHorizontal: GRAY_SCALE.WHITE,
            colorItemTextHover: GRAY_SCALE.WHITE,
            colorItemTextSelected: GRAY_SCALE.WHITE,
            colorItemText: DARK_MODE.TEXT_COLOR,
            colorActiveBarWidth: 0,
            boxShadowSecondary: DARK_MODE.DROP_DOWN_SHADOW,
            controlHeightLG: 32, // Large Menu 항목 높이를 줄임
            controlHeightSM: 22, // Small Menu 항목 높이를 줄임
        },
        Dropdown: {
            boxShadowSecondary: DARK_MODE.DROP_DOWN_SHADOW,
            controlHeight: 32, // Dropdown 높이를 줄임
        },
        Calendar: {
            controlItemBgActive: '#303a4e',
            controlHeight: 32, // Calendar 높이를 줄임
        },
       
        Select: {
            controlOutline: 'transparent',
            controlItemBgActive: DARK_MODE.ACTIVE_BG_COLOR,
            controlItemBgHover: DARK_MODE.HOVER_BG_COLOR,
            boxShadowSecondary: DARK_MODE.DROP_DOWN_SHADOW,
            controlHeight: 32, // Select 높이를 줄임
            controlHeightSM: 28, // Small Select 높이를 줄임
        },
        Input: {
            controlOutline: 'transparent',
            controlHeight: 32, // Input 높이를 줄임
        },
        DatePicker: {
            controlOutline: 'transparent',
            boxShadowSecondary: DARK_MODE.DROP_DOWN_SHADOW,
            controlItemBgActive: DARK_MODE.ACTIVE_BG_COLOR,
            controlItemBgHover: DARK_MODE.HOVER_BG_COLOR,
            controlHeight: 32, // DatePicker 높이를 줄임
            controlHeightSM: 24, // Small DatePicker 높이를 줄임
        },
        Table: {
            colorText: DARK_MODE.TEXT_COLOR,
            colorTextHeading: DARK_MODE.HEADING_COLOR,
            paddingSM: 8, // Table 패딩을 줄임
        },
        
    })
  }
  